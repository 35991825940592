$body-background:#f5f5f5;
$form-border-color: #cbd6e2;
$form-focus-color: #52a9ec;
$orange-hubster: #ff5531;
$blue-hubster: #112232;
$orange-hover: rgba(255, 85, 49, 0.8);
$danger: #f2545b;
$grey-hover: rgba(200, 200, 200, 0.2);
$grey-icon: #a6aab3;

$orange-hubster-palette: (
    50 : #ffebe6,
    100 : #ffccc1,
    200 : #ffaa98,
    300 : #ff886f,
    400 : #ff6f50,
    500 : #ff5531,
    600 : #ff4e2c,
    700 : #ff4425,
    800 : #ff3b1f,
    900 : #ff2a13,
    A100 : #ffffff,
    A200 : #fffafa,
    A400 : #ffcbc7,
    A700 : #ffb4ad,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);