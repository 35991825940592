@use "~@angular/material" as mat;

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "settings";
@import "generic/common";
@import "elements/common";
@import "objects/common";
@import "components/common";
@import "utilities/common";

// Global styles

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: Arial, sans-serif;
  background-color: $body-background;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue-hubster;
  font-weight: 600 !important;
}

@include mat.core();

$custom-toggle-theme: mat.define-light-theme(
  mat.define-palette(mat.$orange-palette),
  mat.define-palette($orange-hubster-palette)
);
@include mat.slide-toggle-theme($custom-toggle-theme);
$custom-orange-theme: mat.define-light-theme(
  mat.define-palette($orange-hubster-palette),
  mat.define-palette($orange-hubster-palette),
  mat.define-palette($orange-hubster-palette)
);
@include mat.select-theme($custom-orange-theme);
@include mat.option-theme($custom-orange-theme);
@include mat.form-field-theme($custom-orange-theme);
@include mat.progress-spinner-theme($custom-orange-theme);
@include mat.paginator-theme($custom-orange-theme);
@include mat.datepicker-theme($custom-orange-theme);
@include mat.select-theme($custom-orange-theme);
@include mat.checkbox-theme($custom-orange-theme);
@include mat.tabs-theme($custom-orange-theme);
@include mat.expansion-theme($custom-orange-theme);

.modal-container {
  .mat-dialog-container {
    background-color: white;
    color: black;
  }
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.loading-container {
  margin: 1rem auto;
}

.error-container {
  color: $danger;
  text-align: center;
  margin: 1rem 0;
}

.text-center {
  text-align: center;
}

.search-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  align-items: center;
}

// Change styles for ng select

.ng-select.ng-select-single.search-select {
  .ng-select-container {
    padding: 0 1rem;
    width: 100%;
    height: 40px;
    outline: none;
    border-radius: 3px;
    border: 1px solid #cbd6e2;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;

    .ng-value-container .ng-input {
      left: 0.9rem;
      top: 0.5rem;
    }
  }

  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: $danger;
      box-shadow: none;
    }
  }
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  font-weight: normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: rgba(0, 0, 0, 0.04);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: rgba(0, 0, 0, 0.12);
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  // box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 0 3px rgb(195 195 195 / 18%);
  box-shadow: none;
}

.invalid-cell {
  color: $danger;
  background-color: lighten($color: $danger, $amount: 25) !important;
}

input {
  @apply border px-2 py-1 rounded-md;
}

.hubster-table-custom {
  width: 100%;
  overflow: auto hidden;

  table {
    margin-top: 5px;
    border-collapse: collapse;
    text-align: left;
    overflow: auto;
    min-width: 100%;

    th {
      padding: 8px 16px;
      border-top: 2px solid $grey-hover;
      background-color: $orange-hover;
      font-weight: 500;
      color: white;
    }

    td {
      padding: 8px 16px;
      border-top: 2px solid $grey-hover;
    }

    .description {
      width: 200px;
    }
  }
}

.tab-active {
  border-bottom: 2px solid rgba(255, 85, 49, 0.8);
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.alert {
  @apply flex items-center gap-4 p-4 text-sm text-blue-800 rounded-lg bg-blue-50;

  p {
    margin-bottom: 0 !important;
  }
}
