// Table styles
.hubster-table {
  width: 100%;
  overflow: auto hidden;

  table {
    border-collapse: collapse;
    text-align: left;
    overflow: auto;
    display: grid;
    min-width: 100%;

    thead, tbody, tr {
      display: contents;
    }

    th {
      padding: 8px 16px;
      border-top: 2px solid $grey-hover;
      background-color: $orange-hover;
      font-weight: 500;
      color: white;
    }

    tr:hover {
      background-color: white;
    }

    td {
      padding: 8px 16px;
      border-top: 2px solid $grey-hover;
      display: flex;
      align-items: center;

      &.complete-row {
        justify-content: center;
        grid-column: 1 / -1;
      }

    }

    .truncate-td {
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
    }
  }
}
